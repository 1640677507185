<template>
    <ModuleWrapper :pageTitle="pageTitle">
        <template v-slot:h_left>
            <PageFilter
                :model="pageModel"
                :key="page_name"
                size="large"
                :page_name="page_name" />
        </template>
        <template v-slot:h_right>
            <a-button 
                v-if="addButton"
                class="header__button"
                icon="plus" 
                size="large"
                type="primary"
                @click="createHandler()" >
                {{ addButton.label ? addButton.label : $t(this.buttonText) }}
            </a-button>
            <component
                :is="settingsButtonWidget"   
                :pageName="page_name"
                class="ml-2" />
        </template>
        <component 
            v-if="tableColumnsList && tableColumnsList.length"
            :is="switchComponent" 
            :listProject="listProject"
            :tableColumnsList="tableColumnsList"
            :pageModel="pageModel"
            :model="pageModel"
            :tableType="tableType"
            :page_name="page_name" />
    </ModuleWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import PageFilter from '@/components/PageFilter'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
export default {
    name: 'GroupListInit',
    components: {
        PageFilter,
        SettingsButton,
        ModuleWrapper
    },
    props: {
        tableType: {
            type: String,
            default: 'groups'
        },
        listProject: {
            type: Boolean,
            default: true
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        },
        page_name: {
            type: String,
            default: 'page_list_project_workgroups.WorkgroupModel'
        },
        buttonSize: {
            type: String,
            default: 'large'
        },
        buttonText: {
            type: String,
            default: 'wgr.add_project'
        },
        templateButtonText: {
            type: String,
            default: 'wgr.templates'
        },
        pageConfig: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        ...mapGetters({
            tableColumns: 'workgroups/tableColumns'
        }),
        tableColumnsList() {
            // console.log(this.$store.workgroups.getters.tableColumns)
            const type = this.listProject ? 'project' : 'group'
            return this.tableColumns(type)
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        switchComponent() {
            return () => import(/* webpackMode: "lazy" */'./TestTable')
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        settingsButtonWidget() {
            return () => import(/* webpackMode: "lazy" */'@/components/TableWidgets/SettingsButton')
        },
        createButton() {
            return this.pageConfig?.headerButtons?.createButton || null
        },
        getRouteInfo() {
            return this.$store.getters['navigation/getRouteInfo'](this.$route.name)
        },  
        addButton() {
            if(this.getRouteInfo?.pageActions?.add) 
                return {
                    label: this.getRouteInfo?.buttonConfig?.label || this.$t(this.buttonText)
                }
            return null
        },
        addTemplateButton() {
            if(this.getRouteInfo?.pageActions?.addTemplate) 
                return {
                    label: this.getRouteInfo?.templateButtonConfig?.label || this.$t(this.templateButtonText)
                }
            return null
        }
    },
    methods: {
        createTemplateHandler() {
            if(this.listProject) {
                this.$router.replace({
                    query: { createProjectTemplate: true }
                })
            }
        },
        testCreateHandler() {
            if(this.listProject) {
                this.$router.replace({
                    // query: { create_project: true }
                    query: { createProject: true }
                })
            } else {
                this.$router.replace({
                    query: { createGroup: true }
                })
            }
        },
        createHandler() {
            if(this.listProject) {
                this.$router.replace({
                    query: { create_project: true }
                    // query: { createProject: true }
                })
            } else {
                this.$router.replace({
                    query: { createGroup: true }
                })
            }
        }
        
    }
}
</script>

<style lang="scss" scoped>
.header__button + .header__button {
    margin-left: 10px;
}
</style>